import React, { lazy, Suspense } from "react";
import type { PageProps } from "keycloakify/login/pages/PageProps";
import type { KcContext } from "./KcContext";
import { useI18n } from "./i18n";

import "./KcPage.css";

const Template = lazy(async () => await import("./Template"));
const LoginExpired = lazy(
  async () => await import("./pages/LoginExpired")
);
const Login = lazy(
  async () => await import("./pages/Login")
);
const Info = lazy(async () => await import("keycloakify/login/pages/Info"));
const Register = lazy(
  async () => await import("./pages/Register")
);
const ResetPassword = lazy(
  async () => await import("./pages/ResetPassword")
);
const UpdatePassword = lazy(
  async () => await import("./pages/UpdatePassword")
);
const Error = lazy(
  async () => await import("./pages/Error")
);
const Logout = lazy(
  async () => await import("./pages/Logout")
);

const classes: PageProps<any, any>["classes"] = {};

export default function KcPage (props: { kcContext: KcContext }) {
  const { kcContext } = props;

  const { i18n } = useI18n({ kcContext });

  return (
    <Suspense>
      {(() => {
        switch (kcContext.pageId) {
          case "login.ftl":
            return (
              <Login
                {...{ kcContext, i18n, Template, classes }}
                doUseDefaultCss={true}
              />
            );
          case "register.ftl":
            return (
              <Register
                {...{ kcContext, i18n, Template }}
                doUseDefaultCss={false}
              />
            );
          case "login-reset-password.ftl":
            return (
              <ResetPassword
                {...{ kcContext, i18n, Template }}
                doUseDefaultCss={false}
              />
            );
          case "login-update-password.ftl":
            return (
              <UpdatePassword
                {...{ kcContext, i18n, Template }}
                doUseDefaultCss={false}
              />
            );
          case "login-page-expired.ftl":
            return (
              <LoginExpired
                {...{ kcContext, i18n, Template }}
                doUseDefaultCss={false}
              />
            );
          case "logout-confirm.ftl":
            return (
              <Logout
                {...{ kcContext, i18n, Template }}
                doUseDefaultCss={false}
              />
            );
          case "info.ftl":
            return (
              <Info
                {...{ kcContext, i18n, Template }}
                doUseDefaultCss={false}
              />
            );
          case "error.ftl":
            return (
              <Error
                {...{ kcContext, i18n, Template, classes }}
                doUseDefaultCss={false}
              />
            );
        }
      })()}
    </Suspense>
  );
}
