import { i18nBuilder } from "keycloakify/login";
import { ThemeName } from "../kc.gen";

const { useI18n, ofTypeI18n } = i18nBuilder
  .withThemeName<ThemeName>()
  .withCustomTranslations({
    // @ts-ignore
    en: {
      forgotPasswordInfoText: "Recover your password for fast online login.",
      errorSubTitle:
        "An error occurred while processing your request. We are already exploring it.",
      backToApplication: "Back to application",
    },
    // @ts-ignore
    uk: {
      errorSubTitle:
        "Сталася помилка під час обробки вашого запиту. Ми її вже вивчаємо.",
      forgotPasswordInfoText:
        "Відновіть свій пароль для швидкого входу в мережу",
      backToApplication: "Повернутися до додатку",
    },
  })
  .build();

type I18n = typeof ofTypeI18n;

export { useI18n, type I18n };
